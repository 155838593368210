(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name neo.directive:errorToast
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="neo">
   <file name="index.html">
   <error-toast></error-toast>
   </file>
   </example>
   *
   */
  angular
  .module('neo')
  .directive('errorToast', errorToast);

  function errorToast() {
    return {
      restrict: 'E',
      scope: {},
      templateUrl: 'directives/error-toast/error-toast-directive.tpl.html',
      replace: false,
      controllerAs: 'errorToast',
      controller: 'ErrorToastCtrl'
    };
  }
}());
